import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Link, LinkVariant, Modal, PhoneIcon } from '@jsmdg/yoshi';
import { a11yCommonMessages } from '../../i18n';
import { type HeaderContext } from '../../types';
import styles from './ContactModal.module.scss';

type ContactModalProps = {
    readonly phoneLabel: string;
    readonly phoneNumber: string;
    readonly phoneCountry: string;
    readonly isOpen: boolean;
    readonly onRequestClose: () => void;
};

const messages = defineMessages({
    headline: {
        defaultMessage: 'Bestellung & Beratung',
    },
    normalServiceHoursTitle: {
        defaultMessage: 'Du erreichst uns zu folgenden Zeiten:',
    },
    christmasServiceHoursTitleStarts: {
        defaultMessage: 'Verlängerte Servicezeiten ab dem',
    },
    christmasServiceHoursTitleEnd: {
        defaultMessage: '1. Adventswochenende:',
    },
    serviceUnavailable: {
        defaultMessage:
            'Aufgrund von Wartungsarbeiten bieten wir am 1. Februar keinen telefonischen Service an.',
    },
    phoneInfoDE: {
        defaultMessage:
            'Es fallen die Kosten deines Providers für Telefonate in das deutsche Festnetz an.',
    },
    phoneInfoAT: {
        defaultMessage:
            'Es fallen die Kosten deines Providers für Telefonate in das österreichische Festnetz an.',
    },
    phoneInfoCH: {
        defaultMessage:
            'Es fallen die Kosten deines Providers für Telefonate in das Schweizer Festnetz an.',
    },
});

const asterisk = (style: string): JSX.Element => <sup className={style}>*</sup>;

const contentRefCallback = (content: HTMLElement): void => {
    if (content) {
        // eslint-disable-next-line no-param-reassign
        content.style.maxWidth = '600px';
    }
};

const ContactModal = ({
    isOpen,
    onRequestClose,
    phoneCountry,
    phoneLabel,
    phoneNumber,
}: ContactModalProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig }: HeaderContext = useFragmentContext();

    const messageId =
        (
            messages as {
                [key: string]: {
                    defaultMessage: string;
                };
            }
        )[`phoneInfo${phoneCountry}`] || messages.phoneInfoDE;
    const phoneInfo = intl.formatMessage(messageId);

    return (
        <Modal
            headline={intl.formatMessage(messages.headline)}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentRef={contentRefCallback}
            a11yCloseText={intl.formatMessage(a11yCommonMessages.a11yCloseText)}
        >
            <div className={classNames('pt-3x', styles.contactInformation)}>
                <Link
                    internal
                    variant={LinkVariant.GreyQuiet}
                    href={`tel:${phoneNumber}`}
                    className={`${styles.phoneNumber} d-inline-flex align-items-center font-complementary`}
                >
                    <PhoneIcon className="mr-1x" />
                    <span>
                        {phoneLabel}
                        {asterisk('ml-0-5x')}
                    </span>
                </Link>
                {tenantConfig.serviceHours.normalServiceHours ? (
                    <div className="theme-text-body-text mt-2x">
                        {intl.formatMessage(messages.normalServiceHoursTitle)}
                        <span className="d-block mt-1x mt-sm-1x">
                            {tenantConfig.serviceHours.normalServiceHours}
                        </span>
                    </div>
                ) : null}
                {tenantConfig.serviceHours.christmasServiceHours ? (
                    <div className="theme-text-body-text mt-2x">
                        {intl.formatMessage(messages.christmasServiceHoursTitleStarts)}{' '}
                        <span className="fw-bold text-nowrap">
                            {intl.formatMessage(messages.christmasServiceHoursTitleEnd)}
                        </span>
                        <span className="d-block mt-1x mt-sm-1x">
                            {tenantConfig.serviceHours.christmasServiceHours}
                        </span>
                    </div>
                ) : null}
                <div className="theme-text-body-text fw-bold mt-2x">
                    {intl.formatMessage(messages.serviceUnavailable)}
                </div>
                <div className={classNames('mt-4x', styles.phoneNumberInfo)}>
                    <span>
                        {asterisk('mr-0-5x')}
                        {phoneInfo}
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export { ContactModal };
// @loadable/component has an issue with the named export which we couldn't resolve so far.
// When using named exports it fails on the second render
// Using a default export for now until we found solution.
export default ContactModal; // eslint-disable-line import/no-default-export
