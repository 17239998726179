import { defineMessages, useIntl } from 'react-intl';

import { type PageType } from '../../../shared/enums/pageType';
import { TrackingListName } from '../../enums/trackingListName';
import { useLastSeenProducts } from '../../hooks/useLastSeenProducts';
import { ProductSlider } from '../ProductSlider';

const messages = defineMessages({
    lastSeenProducts: { defaultMessage: 'Zuletzt gesehen' },
});

type LastSeenProductSliderContentProps = {
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly productIds?: string[];
    readonly verticalPosition?: number;
};

const LastSeenProductSliderContent = ({
    lazyLoad,
    pageType,
    productIds = [],
    verticalPosition,
}: LastSeenProductSliderContentProps): JSX.Element => {
    const intl = useIntl();

    const { products } = useLastSeenProducts(productIds);

    return (
        <ProductSlider
            products={products}
            title={intl.formatMessage(messages.lastSeenProducts)}
            trackingCategory="ProductList"
            trackingListName={TrackingListName.LastSeen}
            dataTestId="last-seen-slider"
            lazyLoad={lazyLoad}
            pageType={pageType}
            verticalPosition={verticalPosition}
        />
    );
};

export { LastSeenProductSliderContent };

export default LastSeenProductSliderContent;
