import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { getHideWelcomePopup } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { ClickEventLabel, ClickEventType } from '@jsmdg/tracking';
import {
    CalendarWithGearIcon,
    ChevronIcon,
    HeartIcon,
    LayerIcon,
    Link,
    LinkVariant,
    LogoutIcon,
    ProfileIcon,
    SettingsIcon,
    SupportIcon,
    TicketsOutlineThickIcon,
} from '@jsmdg/yoshi';
import { getLogoutUrl } from '../../../helper/customerAccountUrlUtils';
import { trackCustomerAccount } from '../../../helper/trackCustomerAccount';
import { type HeaderContext, type Route } from '../../../types';
import { FlyoutBase } from '../FlyoutBase/FlyoutBase';
import styles from './LoggedInCustomerFlyout.module.scss';

type LoggedInCustomerFlyoutProps = {
    readonly onRequestClose: () => void;
    readonly onClickLogout: () => void;
    readonly onMouseEnter: () => void;
};

const messages = defineMessages({
    title: {
        defaultMessage: 'Willkommen zurück',
    },
    accountLabel: {
        defaultMessage: 'Mein Erlebnis Konto',
    },
    profileLabel: {
        defaultMessage: 'Mein Profil',
    },
    settingsLabel: {
        defaultMessage: 'Einstellungen',
    },
    wishlistLabel: {
        defaultMessage: 'Meine Merkliste',
    },
    ordersLabel: {
        defaultMessage: 'Meine Bestellungen',
    },
    vouchersLabel: {
        defaultMessage: 'Gutschein einlösen',
    },
    voucherValidityLabel: {
        defaultMessage: 'Gutscheingültigkeit',
    },
    support: {
        defaultMessage: 'Support kontaktieren',
    },
    logout: {
        defaultMessage: 'Abmelden',
    },
});

const LoggedInCustomerFlyout = ({
    onClickLogout,
    onMouseEnter,
    onRequestClose,
}: LoggedInCustomerFlyoutProps): JSX.Element => {
    const intl = useIntl();
    const { customerInfo, tenantConfig } = useFragmentContext<HeaderContext>();

    const routes: Route[] = [
        {
            id: '1',
            path: '/account#profile',
            title: messages.profileLabel,
            icon: <ProfileIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.MY_PROFILE,
            trackClickElement: 'Customer account profile',
            isInternalLink: true,
        },
        {
            id: '2',
            path: '/account#settings',
            title: messages.settingsLabel,
            icon: <SettingsIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.SETTINGS,
            trackClickElement: 'Customer account settings',
            isInternalLink: true,
        },
        {
            id: '3',
            path: '/wishlist',
            title: messages.wishlistLabel,
            icon: <HeartIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.MY_WISHLIST,
            trackClickElement: 'Customer account wishlist',
            isInternalLink: true,
        },
        {
            id: '4',
            path: '/account#orders',
            title: messages.ordersLabel,
            icon: <LayerIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.MY_ORDERS,
            trackClickElement: 'Customer account orders',
            isInternalLink: true,
        },
        {
            id: '5',
            path: tenantConfig.urls.redemption,
            title: messages.vouchersLabel,
            icon: <TicketsOutlineThickIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.REDEEM_YOUR_VOUCHER,
            trackClickElement: 'Customer account redemption',
            isInternalLink: false,
        },
        {
            id: '6',
            path: tenantConfig.urls.redemption,
            title: messages.voucherValidityLabel,
            icon: <CalendarWithGearIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.VOUCHER_VALIDITY,
            trackClickElement: 'Customer account voucher validity',
            isInternalLink: false,
        },
        {
            id: '7',
            path: tenantConfig.urls.contact,
            title: messages.support,
            icon: <SupportIcon size={20} />,
            trackClickEventLabel: ClickEventLabel.CONTACT,
            trackClickElement: 'Contact Support',
            isInternalLink: false,
        },
    ];

    return (
        <FlyoutBase
            onRequestClose={onRequestClose}
            dataTestId="customer-account-flyout"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onRequestClose}
        >
            <div className={classNames('p-2x', styles.headingWrapper)}>
                <h3>
                    {intl.formatMessage(messages.title)}
                    {customerInfo?.firstName && `, ${customerInfo?.firstName}`}!
                </h3>
                <Link
                    variant={LinkVariant.GreyQuiet}
                    className={styles.text}
                    href="/account"
                    internal
                    iconRight={<ChevronIcon size={18} className={styles.chevronIcon} />}
                    onClick={async event => {
                        await trackCustomerAccount(
                            ClickEventType.TEXT_CLICK,
                            ClickEventLabel.OVERVIEW,
                            event.currentTarget.href,
                            'Overview account',
                            intl.formatMessage(messages.accountLabel),
                        );
                    }}
                >
                    {intl.formatMessage(messages.accountLabel)}
                </Link>
            </div>
            <div className="py-2x">
                {routes.map(route => (
                    <Link
                        key={route.id}
                        variant={LinkVariant.GreyQuiet}
                        href={route.path}
                        internal={route.isInternalLink}
                        data-no-instant
                        iconRight={<ChevronIcon size={18} className={styles.chevronIcon} />}
                        className={classNames(
                            'w-100 p-0 px-2x py-0-5x my-1x justify-content-between',
                            styles.link,
                        )}
                        onClick={() => {
                            trackCustomerAccount(
                                ClickEventType.TEXT_CLICK,
                                route.trackClickEventLabel,
                                route.path,
                                route.trackClickElement,
                                intl.formatMessage(route.title),
                            );
                        }}
                    >
                        <div className="d-flex justify-content-center  align-items-center">
                            <span className="d-flex justify-content-center align-items-center pr-1x">
                                {route.icon}
                            </span>
                            <span>{intl.formatMessage(route.title)}</span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className={classNames('p-2x', styles.footerWrapper)}>
                <Link
                    variant={LinkVariant.GreyQuiet}
                    href="/logout"
                    internal
                    iconLeft={<LogoutIcon size={20} className="mr-1x" />}
                    data-no-instant
                    onClick={async event => {
                        event.preventDefault();

                        const { href } = event.currentTarget;

                        onClickLogout();
                        await trackCustomerAccount(
                            ClickEventType.TEXT_CLICK,
                            ClickEventLabel.LOGOUT,
                            href,
                            'Logout account',
                            intl.formatMessage(messages.logout),
                        );
                        getHideWelcomePopup().remove();
                        window.location.assign(getLogoutUrl(href));
                    }}
                >
                    {intl.formatMessage(messages.logout)}
                </Link>
            </div>
        </FlyoutBase>
    );
};

export { LoggedInCustomerFlyout };
export default LoggedInCustomerFlyout;
